import React from 'react'
import { graphql } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import SEO from '../components/seo'
import Layout from '../containers/layout'
import CategoryList from '../components/CategoryList'
import Pagination from '../components/Pagination'
// import PortableText from '../components/portableText'
import TitleAndDescription from '../components/TitleAndDescription'

export const query = graphql`

  query blogPageQuery($skip: Int = 0) {
    blog: sanityBlog {
      name
      _rawExcerpt(resolveReferences: {maxDepth: 10})
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 20
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          publishedAt
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const { data, errors, pageContext } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  // const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }

  // console.log(data.blog._rawExcerpt)

  return (
    <Layout>
      <socialImage />
      <Container>
        <TitleAndDescription title={data.blog.name} excerpt={data.blog._rawExcerpt} />
        {/* <h1 hidden>Welcome to {data.blog.name}</h1>
        <h3 className='mt-4 mb-5 text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:leading-9"'>
          {data.blog.name}
        </h3> */}
        {/* <div className='markdown mb-8'>
          <PortableText blocks={data.blog._rawExcerpt} />
        </div> */}

        {postNodes && (
          <BlogPostPreviewList title='' nodes={postNodes} browseMoreHref='/archive/' />
        )}

        <CategoryList />
        {data.posts.totalCount > 20 && (
          <Pagination currentPage={pageContext.currentPage} totalCount={data.posts.totalCount} />
        )}

      </Container>
    </Layout>
  )
}

export default BlogPage
