import React from 'react'
import { Link } from 'gatsby'

export default function Pagination ({ totalCount, currentPage }) {
  const totalPages = Math.ceil(totalCount / 2)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  return (
    <nav className='bg-white px- py-3 flex items-center justify-between border-t border-gray-200 sm:px-0'>
      <div className='hidden sm:block'>
        <p className='text-sm leading-5 text-gray-700'>
          Showing&nbsp;
          <span className='font-medium'>{currentPage}&nbsp;
          </span>
          to&nbsp;
          <span className='font-medium'>{totalPages}&nbsp;
          </span>
          of&nbsp;
          <span className='font-medium'>{totalCount}&nbsp;
          </span>
          results&nbsp;
        </p>
      </div>
      <div className='flex-1 flex justify-between sm:justify-end'>
        {prevPage >= 1 ? (
          <Link to={`/blog/${prevPage}`} className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
            Previous
          </Link>
        ) : <span className='pointer-events-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-500 bg-white'>
          Previous
        </span>}
        {nextPage <= totalCount - 2 ? (
          <Link to={`/blog/${nextPage}`} className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
            Next
          </Link>
        ) : (
          <span className='pointer-events-none ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-500 bg-white'>
            Next
          </span>
        )}
      </div>
    </nav>
  )
}
